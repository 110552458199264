import { default as _91_46_46_46page_93plMEWPrPAfMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as eventsiPr0A4oVc9Meta } from "/vercel/path0/pages/events.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryjDl5RbNP1cMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as menue28QHjVRIcMeta } from "/vercel/path0/pages/menu.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___nl",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___en",
    path: "/en/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___nl",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "events___nl",
    path: "/offerte-aanvragen",
    component: () => import("/vercel/path0/pages/events.vue")
  },
  {
    name: "events___en",
    path: "/en/request-a-quote",
    component: () => import("/vercel/path0/pages/events.vue")
  },
  {
    name: "faq___nl",
    path: "/veelgestelde-vragen",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___nl",
    path: "/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___en",
    path: "/en/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "menu___nl",
    path: "/menu",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "menu___en",
    path: "/en/menu",
    component: () => import("/vercel/path0/pages/menu.vue")
  },
  {
    name: "sitemap___nl",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]